@font-face {
font-family: 'font';
src: url(/sv/_next/static/media/288f27b9f6799f13-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: 'font';
src: url(/sv/_next/static/media/82c9a452221bdc4b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 600;
font-style: normal;
}@font-face {font-family: 'font Fallback';src: local("Arial");ascent-override: 120.43%;descent-override: 31.69%;line-gap-override: 0.00%;size-adjust: 78.88%
}.__className_3a8abc {font-family: 'font', 'font Fallback'
}.__variable_3a8abc {--font-neusa: 'font', 'font Fallback'
}

